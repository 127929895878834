<template>
  <span class="check">
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3334 1L5.00008 8.33333L1.66675 5"
        :stroke="color"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true
    }
  },
  name: 'GradeItemCheck'
}
</script>

<style scoped lang="sass">
.check
  position: absolute
  +media((top: (0: 0, 480: rem(6), 992: rem(14))))
  +media((right: (0: rem(2), 480: rem(6), 992: rem(12))))
  pointer-events: none
  // opacity: 0
  // transition: .5s
</style>
