<template>
  <router-link :to="path" class="accordion__links-item swiper-slide">
    <img :src="imageUrl" alt="" />
    <div class="link__text" :content="linkText" v-tippy="{ placement: 'right', maxWidth: 200 }">
      {{ getFormattedStr(linkText) }}
    </div>
    <div class="link__footer" v-if="isLinkToJunction">
      <div class="link__footer-item">{{ currentJunction.count }} {{ junctionUnits }}</div>
    </div>
    <div class="link__footer" v-else>
      <div class="link__footer-item">
        <img src="@/assets/img/nav/slide-square.svg" class="img" alt="" />
        {{ format(allSectors[item.sectorIndex].square) }} {{ $t('message.units.m2') }}
      </div>
      <div class="link__footer-item">
        <img src="@/assets/img/nav/slide-junctions.svg" class="img" alt="" />
        {{ sum(allSectors[item.sectorIndex].junctions) }}
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapState } from 'vuex'
import { findJunctionOriginalName, sliceStr } from '@/utils'
import { formatBigNumber } from '@/utils/customFunctions'

const junctionTypes = {
  linear: 'mp',
  chiseled: 'pc'
}

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  name: 'ResultUsedMaterialsNavItem',
  methods: {
    getFormattedStr(str) {
      return sliceStr(str)
    },
    format(n) {
      return formatBigNumber(n)
    },
    sum(arr) {
      return arr.length
      // return sumElements(arr, 'count')
    }
  },
  computed: {
    ...mapState({
      allSectors: state => state.sectors,
      allJunctions: state => state.junctions
    }),
    isLinkToJunction() {
      return Object.prototype.hasOwnProperty.call(this.item, 'junctionIndex')
    },
    path() {
      return this.isLinkToJunction
        ? `/${this.$i18n.locale}/details/${this.item.sectorIndex}/junction/${this.item.junctionIndex}`
        : `/${this.$i18n.locale}/details/${this.item.sectorIndex}`
    },
    currentSector() {
      return this.allSectors[this.item.sectorIndex]
    },
    currentJunction() {
      return this.currentSector.junctions[this.item.junctionIndex]
    },
    isCustomSector() {
      return this.currentSector.isCustom
    },
    isCustomJunction() {
      return this.currentSector.junctions[this.item.junctionIndex].isCustom
    },
    junctionUnits() {
      return this.$i18n.t(`message.units.${junctionTypes[this.currentJunction.type]}`)
    },
    imageUrl() {
      if (this.isLinkToJunction) {
        return this.isCustomJunction
          ? require('@/assets/img/icons/junctions-nav--custom.svg')
          : require('@/assets/img/icons/junctions-nav--tn.svg')
      } else {
        return this.isCustomSector
          ? require('@/assets/img/nav/slide-custom--color.svg')
          : require('@/assets/img/nav/slide-tn--color.svg')
      }
    },
    linkText() {
      if (this.isLinkToJunction) {
        // prettier-ignore
        return this.isCustomJunction
          ? this.currentJunction.customJunctionName
          : findJunctionOriginalName(
            this.item.junctionIndex,
            this.currentSector.junctions,
            this.allJunctions
          )
      } else {
        return this.allSectors[this.item.sectorIndex].name
      }
    }
  }
}
</script>

<style scoped lang="sass">
.accordion__links-item
  background: #FFFFFF
  cursor: pointer
  // transition: .5s
  text-decoration: none
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  padding: rem(29) rem(12) rem(9)
  border: rem(1) solid #ECECEC
  box-sizing: border-box
  height: auto
  .link
    &__text
      padding: rem(17) 0 rem(8)
      @extend .fs-12
      text-align: left
      font-weight: bold
      color: $black
      width: 100%
    &__footer
      margin-top: auto
      display: flex
      align-items: center
      justify-content: space-between
      flex-wrap: wrap
      width: 100%
      @extend .fs-12
      color: $dark-grey
      flex-direction: row
      &-item
        display: flex
        flex-direction: row
        align-items: center
        .img
          margin-right: rem(6)
</style>
