<template>
  <form @submit.prevent="checkForm" novalidate class="form">
    <span class="title" v-if="isIdeaGrade">
      {{ this.$t('message.calculatorRating.idea') }}
    </span>
    <form-input
      v-if="!isGoodGrade"
      :field="fields.message"
      v-model.trim="$v.message.$model"
      @input="delayTouch($v.message)"
      :is-error="$v.message.$error"
    >
      <template #errors>
        <error v-if="!$v.message.required">
          {{ errors.required }}
        </error>
      </template>
    </form-input>
    <form-input
      v-if="!isGoodGrade"
      :field="fields.email"
      v-model.trim="$v.email.$model"
      @input="delayTouch($v.email)"
      :is-error="$v.email.$error"
    >
      <template #errors>
        <error v-if="!$v.email.required">
          {{ errors.required }}
        </error>
        <error v-if="!$v.email.email">
          {{ errors.email }}
        </error>
      </template>
    </form-input>
    <form-input
      v-if="!isGoodGrade"
      :field="fields.name"
      v-model.trim="$v.name.$model"
      @input="delayTouch($v.name)"
      :is-error="$v.name.$error"
    >
      <template #errors>
        <error v-if="!$v.name.required">
          {{ errors.required }}
        </error>
      </template>
    </form-input>
    <form-footer :is-loading="isLoading" :is-need-credentials="!isGoodGrade" />
  </form>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import FormInput from '@/components/smart/CalculatorRating/Form-input'
import { fields, errors, gradesValues } from '@/utils/rating'
import Error from '@/components/smart/CalculatorRating/error'
import FormFooter from '@/components/smart/CalculatorRating/Form-footer'

const touchMap = new WeakMap()

const emailValidations = {
  email,
  required
}
const nameValidations = {
  required
}
const messageValidations = {
  required
}

const totalValidation = {
  email: emailValidations,
  name: nameValidations,
  message: messageValidations
}

const validationsRules = {
  [gradesValues.good]: {},
  [gradesValues.neutral]: {
    email: emailValidations,
    name: nameValidations,
    message: {}
  },
  [gradesValues.idea]: totalValidation,
  [gradesValues.bad]: totalValidation
}

export default {
  props: {
    isLoading: {
      type: Boolean
    },
    currentGrade: {
      type: String,
      required: true
    }
  },
  components: { FormFooter, Error, FormInput },
  mixins: [validationMixin],
  validations() {
    return validationsRules[this.currentGrade]
  },
  name: 'Rating-form',
  data: () => ({
    email: '',
    name: '',
    message: ''
  }),
  watch: {
    currentGrade() {
      this.resetValidations()
    }
  },
  methods: {
    async resetValidations() {
      await this.$nextTick()
      this.$v.$reset()
    },
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },
    checkForm() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const { name, email, message, isGoodGrade } = this
        const formData = {
          fio: name,
          email,
          message
        }
        this.$emit('sendForm', isGoodGrade ? {} : formData)
      }
    }
  },
  computed: {
    fields: () => fields,
    // fields() {
    //   return Object.fromEntries(
    //     Object.entries(fields).map(([key, val]) => {
    //       return [
    //         key,
    //         {
    //           ...val,
    //           placeholder: this.$t(`message.calculatorRating.fields.${key}.placeholder`),
    //           label: this.$t(`message.calculatorRating.fields.${key}.label`)
    //         }
    //       ]
    //     })
    //   )
    // },
    errors() {
      return Object.fromEntries(
        Object.entries(errors).map(([key, val]) => [
          key,
          this.$t(`message.calculatorRating.errors.${val}`)
        ])
      )
    },
    isGoodGrade() {
      return this.currentGrade === gradesValues.good
    },
    isIdeaGrade() {
      return this.currentGrade === gradesValues.idea
    }
  }
}
</script>
<style lang="sass" scoped>
.form
  margin-top: rem(24)
  display: grid
  +media((grid-template-column: (768: 1fr 1fr)))
  grid-column-gap: rem(30)
  +media((grid-row-gap: (0: rem(20), 768: rem(25))))
  .title
    +media((grid-column: (768: span 2)))
    @extend %20
    font-weight: bold
// .slide-enter-active, .slide-leave-active
  transition: .2s
// .slide-enter, .slide-leave-to
  filter: blur(5px)
  // transform: translateY(-100%)
</style>
