<template>
  <div class="table-body__item-nav">
    <div class="content">
      <div class="title">
        {{ $t('message.result.usedMaterials.nav.title') }}
      </div>
      <div class="items">
        <swiper :options="swiperOption" ref="mySwiper">
          <result-used-materials-nav-item
            v-for="item in normalizedIndices"
            :item="item"
            :key="item.uid"
          />
          <div class="swiper-scrollbar" slot="scrollbar"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { getRandomId } from '@/utils'
import { formatBigNumber } from '@/utils/customFunctions'
import { mapState } from 'vuex'
import ResultUsedMaterialsNavItem from '@/components/smart/Result/ResultUsedMaterialsNavItem'
export default {
  components: { ResultUsedMaterialsNavItem },
  props: {
    indices: Array
  },
  data: () => ({
    swiperOption: {
      slidesPerView: 5.5,
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      scrollbar: {
        el: '.swiper-scrollbar',
        hide: false
      },
      breakpoints: {
        320: {
          slidesPerView: 1.9
        },
        360: {
          slidesPerView: 2.1
        },
        480: {
          slidesPerView: 2.5
        },
        575: {
          slidesPerView: 3
        },
        650: {
          slidesPerView: 4
        },
        700: {
          slidesPerView: 4.5
        },
        767: {
          slidesPerView: 5.5
        }
      }
    }
  }),
  methods: {
    format(n) {
      return formatBigNumber(n)
    },
    sum(arr) {
      return arr.length
      // return sumElements(arr, 'count')
    }
  },
  computed: {
    ...mapState({
      allSectors: state => state.sectors
    }),
    swiper() {
      return this.$refs.mySwiper.swiper
    },
    normalizedIndices() {
      return this.indices.map(item => {
        // prettier-ignore
        return !isNaN(item)
          ? {
            sectorIndex: item,
            uid: getRandomId()
          }
          : {
            ...item,
            uid: getRandomId()
          }
      })
    }
  }
}
</script>
<style lang="sass">
.used-materials
  &__table
    .table-body
      &__item
        &-nav
          .swiper
            &-container
              cursor: pointer
              margin: 0
              +media((padding-bottom: (320: rem(32), 960: rem(28))))
              &:hover
                .swiper
                  &-scrollbar
                    &-drag
                      background: rgba(227, 7, 19, 0.5)
                > .swiper-scrollbar
                  background: rgba(0, 0, 0, 0.1)
          .swiper
            &-scrollbar
              height: rem(4)
              bottom: 0
              width: 100%
              left: 0
              &-drag
                border-radius: 0
                background: rgba(227, 7, 19, 0.25)
</style>
<style scoped lang="sass">
.used-materials
  &__table
    .table-body
      &__item
        &-nav
          padding: rem(24) calc(100% / 24) rem(18)
          grid-column: 1 / 5
          grid-row-start: 2
          // display: grid
          // grid-template-columns: 1fr 22fr 1fr
          .content
            // grid-column-start: 2
            display: block
          .title
            @extend .fs-14
            color: $black
            font-weight: bold
            padding-bottom: rem(20)
</style>
