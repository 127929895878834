<template>
  <section class="result-page">
    <div class="container">
      <div class="result-page__title unprint">
        {{ $t('message.result.head.title') }}
      </div>
      <div class="common-pane result-page__pane">
        <div class="pane-item">
          <div class="count">{{ sectorsCount }}</div>
          <div class="text">{{ getFormattedWord('sector') }}</div>
        </div>
        <div class="pane-item">
          <div class="count">{{ junctionsCount }}</div>
          <div class="text">{{ getFormattedWord('junctions') }}</div>
        </div>
        <div class="pane-item">
          <div class="text">
            {{ $t('message.result.head.square') }}
          </div>
          <div class="count">
            {{ sumSquare(allSectors) }} <span class="units">{{ $t('message.units.m2') }}</span>
          </div>
        </div>
      </div>
      <Result-sectors-nav />
      <Result-used-materials />
      <Result-used-materials-by-sectors />
      <Addons placement="total" />
      <calculator-rating />
    </div>
    <Prefooter :isAuth="isAuth" @savePdf="savePdf" @saveXls="saveXls" />
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
  </section>
</template>

<script>
import { pages } from '@/utils'
// import { mapState, mapActions, mapMutations } from 'vuex'
import { mapState, mapActions } from 'vuex'
import { sumElements, formatWord } from '@/utils/customFunctions'
import { isResultPagePermitted } from '@/middlewares'
import ResultSectorsNav from '@/components/smart/ResultSectorsNav'
import ResultUsedMaterials from '@/components/smart/Result/ResultUsedMaterials'
import ResultUsedMaterialsBySectors from '@/components/smart/Result/ResultUsedMaterialsBySectors'
import Addons from '@/components/dump/Addons'
import Prefooter from '@/components/dump/Prefooter'
import CalculatorRating from '@/components/smart/CalculatorRating'
import { stepNumbers } from '@/utils/stepNumbers'
export default {
  components: {
    CalculatorRating,
    ResultSectorsNav,
    Addons,
    Prefooter,
    ResultUsedMaterials,
    ResultUsedMaterialsBySectors
  },
  methods: {
    ...mapActions({
      updateStep: 'updateStep',
      getResults: 'getResults',
      getSavedProject: 'getSavedProject',
      fetchData: 'fetchData',
      getPdf: 'getPdf',
      //   getXls: 'getXls'
      // }),
      // ...mapMutations('moderator', {
      //   SET_CURRENT_CALCULATION_HASH: 'SET_CURRENT_CALCULATION_HASH'
      getXls: 'getXls',
      checkTNTokenAction: 'checkTNToken'
    }),
    sumSquare(arr) {
      return sumElements(arr, 'square')
    },
    getFormattedWord(type) {
      if (type === 'sector') {
        return formatWord(this.$t('message.result.head.words').sectors, this.sectorsCount)
      } else {
        return formatWord(this.$t('message.result.head.words').junctions, this.junctionsCount)
      }
    },
    savePdf() {
      this.getPdf({
        lang: this.$i18n.locale,
        type: 'total',
        fileName: 'Общий расчет',
        domenName: `${location.origin}/${this.$i18n.locale}`,
        header: {
          sectorsCount: this.sectorsCount,
          junctionsCount: this.junctionsCount,
          square: this.sumSquare(this.allSectors)
        },
        page: pages.result
      })
    },
    saveXls() {
      this.getXls({
        lang: this.$i18n.locale,
        type: 'total',
        fileName: 'Общий расчет',
        sectorIndex: null,
        junctionIndex: null,
        title: null,
        page: pages.result
      })
    }
  },
  mounted() {
    const { object: hash, tnt: token } = this.$route.query
    if (token) {
      this.checkTNTokenAction(token)
    }
    if (hash) {
      // typeof hash !== 'undefined'
      if (this.isAuth) {
        this.SET_CURRENT_CALCULATION_HASH(hash)
      }
      this.fetchData(this.$i18n.locale)
      this.getSavedProject({
        lang: this.$i18n.locale,
        hash,
        page: 'result',
        userId: this.userId
      })
    } else {
      if (this.allSystems.length === 0 || !isResultPagePermitted(this.allSectors)) {
        this.$router.push(`/${this.$i18n.locale}/`)
      } else {
        this.getResults(this.$i18n.locale)
      }
    }
    this.updateStep(stepNumbers.result)
  },
  computed: {
    ...mapState({
      allSectors: state => state.sectors,
      isLoading: state => state.pages.result.isLoading,
      allSystems: state => state.systems
    }),
    ...mapState('moderator', {
      isAuth: state => state.isAuth,
      userId: state => state.userId
    }),
    sectorsCount() {
      return this.allSectors.length
    },
    junctionsCount() {
      return this.allSectors.reduce(
        (accumulator, currentValue) => accumulator + currentValue.junctions.length,
        0
      )
    }
  }
}
</script>

<style scoped lang="sass">
.result-page
  &__title
    font-weight: bold
    +media((font-size: (320: rem(18), 960: rem(24))))
    +media((margin: (0: rem(32) auto rem(16), 960: rem(35) 0 rem(20))))
    +media((width: (320: calc(100% - 32px), 650: 92%, 960: 100%)))
  &__pane
    padding: rem(16) 0
    flex-direction: row
    +media((align-items: (320: flex-start, 960: center)))
    justify-content: flex-start
    +media((flex-wrap: (320: wrap, 400: nowrap)))
    +media((display: (320: flex, 400: grid, 480: flex)))
    grid-template-columns: 1fr 1fr 1fr
    .pane-item
      box-sizing: border-box
      display: flex
      justify-content: flex-start
      height: 100%
      +media((align-items: (320: flex-start, 960: center)))
      +media((flex-direction: (320: column, 960: row)))
      +media((padding: (320: 0 rem(24), 400: 0 0 0 rem(24), 480: 0 rem(24), 960: 0 rem(32))))
      &:not(:first-child)
        box-shadow: inset rem(1) 0 0 #ECECEC
      &:not(:last-child)
        .text
          +media((padding-left: (320: 0, 960: rem(16))))
      &:last-child
        +media((margin-left: (320: unset, 960: auto)))
        +media((flex-direction: (320: column-reverse, 960: row)))
        +media((padding-top: (320: rem(16), 400: 0)))
        .text
          +media((padding-right: (320: 0, 960: rem(16))))
      .count
        font-style: normal
        font-weight: bold
        +media((font-size: (320: rem(24), 960: rem(28))))
        +media((padding-bottom: (320: rem(4), 960: 0)))
        line-height: 140%
        color: $black
      .text
        @extend .fs-14
        +media((font-size: (320: rem(12), 960: rem(14))))
</style>
