<template>
  <div class="used-materials" v-if="isShowTotalMaterials">
    <div class="details-title">
      {{ $t('message.result.usedMaterials.table.titleBySectors') }}
    </div>
    <div
      class="used-materials__table used-materials__table--total"
      :class="{ active: currentTableItemExpand === `table-sector-${i}` }"
      v-for="(item, i) in materialsBySector"
      :key="`table-${i}`"
    >
      <div class="table-head" @click="toggleTableItem(`table-sector-${i}`)">
        <div class="title">{{ getSectorName(i) }}</div>
        <!--<div class="chevron">
          <input type="radio" v-model="currentTableItemExpand" :value="`table-sector-${i}`" :id="`table-sector-${i}`">
          <label :for="`table-sector-${i}`"><img src="@/assets/img/icons/table-chevron.svg" alt="↓"></label>
        </div>-->
        <div
          class="chevron"
          :class="{ 'chevron--active': currentTableItemExpand === `table-sector-${i}` }"
        >
          <img src="@/assets/img/icons/table-chevron.svg" alt="↓" />
        </div>
      </div>
      <Details-used-materials-total
        v-if="currentTableItemExpand === `table-sector-${i}`"
        :used-materials="item"
        :is-slicing-need="false"
        :is-in-result-page="true"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DetailsUsedMaterialsTotal from '@/components/smart/Details/DetailsUsedMaterialsTotal'
export default {
  data: () => ({
    currentTableItemExpand: null
  }),
  components: {
    DetailsUsedMaterialsTotal
  },
  methods: {
    getSectorName(index) {
      return this.allSectors[index].name
    },
    toggleTableItem(val) {
      this.currentTableItemExpand = this.currentTableItemExpand === val ? null : val
    }
  },
  computed: {
    ...mapState({
      // materialsBySector: state => state.result.total.materials,
      result: state => state.result,
      allSectors: state => state.sectors
    }),
    materialsBySector() {
      return this.result.total.materials
    },
    isShowTotalMaterials() {
      return (
        Object.prototype.hasOwnProperty.call(this.result, 'total') &&
        this.result.total.materials.length > 0
      )
    }
  }
}
</script>

<style scoped lang="sass">
@import "~@/assets/sass/result-used-materials.sass"
</style>
